.table {
  margin-top: 20px;
  overflow-y: scroll;
  height: 390px;
  /* color: #696161; */
  color: #efefef;
  background-color: #2b2e49;
  border-radius: 5px;
}

.table tr {
  display: flex;
  justify-content: space-between;
}

.table td {
  padding: 0.5rem;
}
/* .table tr:nth-of-type(odd) {
  background-color: #f3f2f8;
} */
